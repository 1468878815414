import React, {ComponentProps, FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addDefaultProps,
  addHandlers,
  addStateHandlers,
} from 'ad-hok'
import {isDate} from 'lodash/fp'
import {format} from 'date-fns/fp'
import subMonths from 'date-fns/subMonths'
import {TFunction} from 'i18next'
import {declarePropTypesNarrowing} from 'ad-hok-utils'

import addRouteParams from 'utils/addRouteParams'
import {addApplicationForViewQuery} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addTranslationHelpers} from 'utils/i18n'
import Heading from 'components/Heading'
import {getExtendedName} from 'utils/name'
import {makeClasses, addClasses} from 'theme'
import Body1 from 'components/Body1'
import {getFormattedPhoneNumber} from 'utils/phone'
import {
  CI_STATUS_US_CITIZEN,
  TAX_FILING_STATUS_NOT_FILING,
  TAX_FILING_STATUS_DEPENDENT,
  TAX_FILING_STATUS_FILING_JOINTLY,
  // RELATIONSHIP_TYPE_SPOUSE,
  WHO_SUPPORT_WITHOUT_INCOME_SELF,
  WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  CI_STATUS_LEGAL_RESIDENT,
  // RELATIONSHIP_TYPE_PARENT,
} from 'components/EditPersonForm/schema'
import {
  includesMonth,
  getDateFromMonthAndYear,
  getAmountForMonth,
} from 'utils/income'
import {formatCurrency} from 'utils/number'
import {getFormattedSsn} from 'utils/ssn'
import {ApplicationForView_application_MedicaidApplication} from 'graphql/deserializedTypes/ApplicationForView'
import {
  addApplyStateMedicaidMutation,
  addNjMedicaidApplicationStatesQuery,
} from 'graphql/generated'
import {NJ_MEDICAID_STATE_QUERY} from 'graphql/queries'
import Button from './Button'
import {
  getAllRelationships,
  getHouseholdMembersFromRelationships,
  getHeadOfHouseholdParentOrLegalGuardian,
  overrideFields,
  getAllRelationshipDetails,
  evaluateDerivedRelationshipForTaxFiler,
  getAdditionalComments,
} from 'utils/medicaidValidation'
import {NjMedicaidApplicationStates_njMedicaidApplicationStates} from 'graphql/types/NjMedicaidApplicationStates'
import {addMedicaidPortal} from 'utils/configContext'

const classes = makeClasses((theme) => ({
  container: {
    margin: theme.spacing(2),
  },
  header: {
    fontSize: 28,
    marginBottom: theme.spacing(4),
  },
  itemValue: {
    fontWeight: 'bold',
  },
  sectionHeader: {
    fontSize: 20,
    marginBottom: theme.spacing(1),
  },
  subsectionHeader: {
    fontSize: 18,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    textTransform: 'uppercase',
  },
  itemLabel: {
    fontWeight: 400,
  },
  error: {
    color: 'red',
  },
  success: {
    color: 'green',
  },
  itemContainer: {
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
  },
  sectionContainer: {
    marginBottom: theme.spacing(4),
  },
  subheader: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}))

const ItemValue: FC = flowMax(
  addDisplayName('ItemValue'),
  addClasses(classes),
  ({classes, children}) => (
    <div>
      <Body1 className={classes.itemValue}>{children}</Body1>
    </div>
  )
)
/* const requiredFields: Array<string> = [
  'Home Addr1/Street',
  'Home City',
  // 'Home County',
  'Home State',
  'Home Zip',
  // 'Phone No',
  'Email',

  'FirstName',
  // 'MiddleName',
  'LastName',
  'DOB',
  'Sex',
  'Is Pregnant',
  'Number of babies expected',
  'Pregnant Due Date',
  'Marital Status',

  'Does this person want Presumptive Eligibility?',
  'Evaluate for Plan First',
  'SSN',
  'Does this person have Health Insurance?',
  'Name of insurance company',
  'Insurance policy number',
  'Is this person currently enrolled in Medicaid?',
  'Race',
  'Are you on anyone in your family Native American Indian or Alaska Native?',
  'US Citizen?',
  'If you are not a US Citizen, please enter your date of entry',
  'Immigration Status',
  'Document Type',
  'Alien/USCIS Number',
  'Immigration card #',
  'AKA',
  'Were you in foster care at age 18 or older?',
  'Full-time student?',
  'Has this person had other health insurance in the last three months other than Medicaid?',
  'Does the member requesting coverage have medical bills for the last 3 months that have not been paid?',

  'Employment Type',
  'Employer Name',
  'Work Type',
  'Employer Address1',
  'Employer Address city',
  'Employer Address state',
  'Employer Address zip',
  'Employer work phone number',
  'Payment Type',
  'Job start date (mm/yyyy)',
  'Job end date (mm/yyyy)',
  'Payment Period:',
  'Work income (before taxes per pay period)',
  'Monthly income:',
  'Has this person had a change in their employment status in the last 6 months?',

  'Are you planning to file taxes?',
  'Will you be claimed as a dependent on someone’s tax return?',
  'If Yes, please list the name of the tax filer:',
  'How are you related to tax filer:',
  'If yes, name of spouse:',
  'Will you file jointly with spouse?',
  'Will you claim any dependents on your tax return?',
  'Dependent Member:',

  // 'Who is your child’s doctor?',
  // 'Address',
  // 'Who is your doctor?',
  // 'Address',
  'Choose Health Plan:',
  'Head of the household',

  'What language you speak at home',

  'Income/Additional Comments',
]
// const isRequiredField = (label: string): boolean =>
requiredFields.indexOf(label) >= 0

const conditionallyRequiredFields: Array<string> = [
  'Is Pregnant',
  'Number of babies expected',
  'Pregnant Due Date',
  'Name of insurance company',
  'Insurance policy number',
  'Race',
  'If you are not a US Citizen, please enter your date of entry',
  // 'Alien/USCIS Number',
  // 'Immigration card #',
  // 'AKA',
  // 'Were you in foster care at age 18 or older?',
  'Has this person had other health insurance in the last three months other than Medicaid?',
  'Does the member requesting coverage have medical bills for the last 3 months that have not been paid?',

  // 'Employer Name',
  // 'Work Type',
  // 'Employer Address1',
  // 'Employer Address city',
  // 'Employer Address state',
  // 'Employer Address zip',
  // 'Employer work phone number',
  // 'Payment Type',
  // 'Job start date (mm/yyyy)',
  // 'Job end date (mm/yyyy)',
  // 'Payment Period:',
  // 'Work income (before taxes per pay period)',
  // 'Monthly income:',
  // 'Has this person had a change in their employment status in the last 6 months?',

  // 'Are you planning to file taxes?',
  // 'Will you be claimed as a dependent on someone’s tax return?',
  // 'If Yes, please list the name of the tax filer:',
  // 'How are you related to tax filer:',
  // 'If yes, name of spouse:',
  // 'Will you file jointly with spouse?',
  // 'Will you claim any dependents on your tax return?',
  // 'Dependent Member:',

  // 'Who is your child’s doctor?',
  // 'Address',
  // 'Who is your doctor?',
  // 'Address',
]
const isConditionallyRequiredField = (label: string): boolean =>
  conditionallyRequiredFields.indexOf(label) >= 0 && true */

interface ItemProps {
  label: string
  value?: string | Date | number | boolean | null
  required: boolean
  inValid?: boolean
  onlyLabel?: boolean
  dateFormat?: string
}

const Item: FC<ItemProps> = flowMax(
  addDisplayName('Item'),
  addDefaultProps({
    dateFormat: 'M/d/yy',
  }),
  addProps(({value, dateFormat}) => ({
    valueFormatted: isDate(value)
      ? format(dateFormat)(value)
      : value === true
      ? 'Yes'
      : value === false
      ? 'No'
      : value,
  })),
  addProps(({required, valueFormatted, children, inValid = false}) => ({
    _inValid:
      inValid ||
      (required && !valueFormatted && !React.Children.count(children)),
  })),
  addClasses(classes),
  ({label, valueFormatted, _inValid, onlyLabel, classes, children}) => (
    <div className={classes.itemContainer}>
      <span className={`${classes.itemLabel} ${_inValid && classes.error}`}>
        {`${label}`}
        {!onlyLabel && ': '}
      </span>
      <span>{children ?? <ItemValue>{valueFormatted}</ItemValue>}</span>
    </div>
  )
)

interface SectionProps {
  label: string
  warningLabel?: string
}

const Section: FC<SectionProps> = flowMax(
  addDisplayName('Section'),
  addClasses(classes),
  ({label, warningLabel, children, classes}) => (
    <div className={classes.sectionContainer}>
      <Heading component="h4" className={classes.sectionHeader}>
        {label}{' '}
        <span className={classes.error}>
          {warningLabel && ` (${warningLabel})`}
        </span>
      </Heading>
      {children}
    </div>
  )
)

const getRelationshipToTaxFiler = ({
  person,
  application,
  _taxFilerOfDependent,
}: {
  person: any
  application: any
  _taxFilerOfDependent: any | null | undefined
}): string | null | undefined => {
  if (!_taxFilerOfDependent) return
  // Patient is tax filer
  if (_taxFilerOfDependent.id?.value === application.person.id?.value) {
    const relationshipToPatient = application.person.relationships.find(
      ({otherPerson}: {otherPerson: any}) =>
        otherPerson.id?.value === person.id?.value
    )
    if (!relationshipToPatient) return
    return relationshipToPatient.relationshipType?.value
  } else {
    const taxPayerRelationshiptoPatient = application.person.relationships.find(
      ({otherPerson}: {otherPerson: any}) =>
        otherPerson.id?.value === _taxFilerOfDependent.id?.value
    )
    if (person.id?.value === application.person.id?.value) {
      // this is a patient who is not a tax filer
      if (!taxPayerRelationshiptoPatient) return
      return taxPayerRelationshiptoPatient.reverseRelationshipType?.value
    } else {
      // this is one of other members
      const relationshipOfDependentToPatient = application.person.relationships.find(
        ({otherPerson}: {otherPerson: any}) =>
          person.id?.value === otherPerson.id?.value
      )
      if (taxPayerRelationshiptoPatient && relationshipOfDependentToPatient) {
        const _relationshipOfDependentToPatient =
          relationshipOfDependentToPatient.relationshipType?.value
        const _relationshipOfTaxFilerToPatient =
          taxPayerRelationshiptoPatient.relationshipType?.value

        console.log({
          person: person.firstName.value,
          _relationshipOfDependentToPatient,
          _relationshipOfTaxFilerToPatient,
        })
        const {
          relationship,
        }: // reverse_relationship_type,
        {
          relationship: string
          // reverse_relationship_type: string
        } = evaluateDerivedRelationshipForTaxFiler(
          _relationshipOfDependentToPatient,
          _relationshipOfTaxFilerToPatient
        )
        return relationship
      }
      return
    }
  }
}

const getNameOfSpouseFilingJointly = ({
  person: {taxFilingStatus},
  person,
  application: {
    person: {relationships},
  },
  application,
  t,
}: {
  person: any
  application: any
  t: TFunction
}):
  | {
      name: string
      id: string
    }
  | undefined => {
  if (taxFilingStatus?.value !== TAX_FILING_STATUS_FILING_JOINTLY) return
  const isClient = person.id?.value === application.person.id?.value

  if (isClient) {
    const jointlyFiler = relationships.find(
      ({otherPerson}: {otherPerson: any}) =>
        otherPerson?.taxFilingStatus?.value === TAX_FILING_STATUS_FILING_JOINTLY
    )
    if (!jointlyFiler) return
    return {
      name: getExtendedName({
        ...{
          firstName: jointlyFiler.otherPerson?.firstName.value,
          lastName: jointlyFiler.otherPerson?.lastName.value,
          preferredName: '',
          middleName: jointlyFiler.otherPerson?.middleName.value,
          suffix: '',
        },
        t,
      }),
      id: jointlyFiler.otherPerson?.id.value,
    }
  } else {
    const otherJointlyFilter = [
      application.person,
      ...relationships
        .filter(
          ({otherPerson}: {otherPerson: any}) =>
            otherPerson.id?.value !== person.id?.value
        )
        .map(({otherPerson}: {otherPerson: any}) => otherPerson),
    ].find(
      ({taxFilingStatus}: {taxFilingStatus: any}) =>
        taxFilingStatus?.value === TAX_FILING_STATUS_FILING_JOINTLY
    )
    if (otherJointlyFilter) {
      return {
        name: getExtendedName({
          ...{
            firstName: otherJointlyFilter.firstName.value,
            lastName: otherJointlyFilter.lastName.value,
            preferredName: '',
            middleName: otherJointlyFilter.middleName.value,
            suffix: '',
          },
          t,
        }),
        id: otherJointlyFilter.id.value,
      }
    } else {
      return
    }
  }
}

const SubsectionHeader: FC = flowMax(
  addDisplayName('SubsectionHeader'),
  addClasses(classes),
  ({children, classes}) => (
    <Heading component="h5" className={classes.subsectionHeader}>
      {children}
    </Heading>
  )
)

interface IncomeInformationSectionProps {
  person: any
  application: any
}

const IncomeInformationSection: FC<IncomeInformationSectionProps> = flowMax(
  addDisplayName('IncomeInformationSection'),
  addProps(
    () => ({
      thisMonth: new Date(),
    }),
    []
  ),
  addProps(
    ({thisMonth}) => ({
      lastMonth: subMonths(thisMonth, 1),
    }),
    ['thisMonth']
  ),
  addProps(
    ({person: {incomeSources, deductions}, thisMonth, lastMonth}) => ({
      incomeSources: incomeSources.filter(
        (incomeSource: any) =>
          includesMonth(thisMonth)(incomeSource) ||
          includesMonth(lastMonth)(incomeSource)
      ),
      deductions: deductions.filter(
        (deduction: any) =>
          includesMonth(thisMonth)(deduction) ||
          includesMonth(lastMonth)(deduction)
      ),
    }),
    ['person', 'thisMonth', 'lastMonth']
  ),
  addProps(
    ({
      person: {id, dependentOfTaxFilerId},
      application: {householdMembers},
    }) => ({
      _taxFilerOfDependent:
        dependentOfTaxFilerId?.value &&
        id.value &&
        dependentOfTaxFilerId?.value !== id.value
          ? householdMembers.find(
              ({
                person: {id: personId},
              }: {
                person: {id: {value: string; required: boolean}}
              }) => personId.value === dependentOfTaxFilerId.value
            )?.person
          : null,
    }),
    ['person', 'application']
  ),
  addProps(
    ({application, _taxFilerOfDependent, person}) => ({
      _relationshipWithTaxFiler: getRelationshipToTaxFiler({
        _taxFilerOfDependent,
        application,
        person,
      }),
    }),
    ['application', '_taxFilerOfDependent', 'person']
  ),
  addTranslationHelpers,
  addProps(
    ({person, application: {householdMembers}, application, t}) => {
      const jointlyFiler = getNameOfSpouseFilingJointly({
        person,
        application,
        t,
      })
      const dependentOfJointlyFiler = jointlyFiler?.id
        ? [
            ...householdMembers
              .filter(
                ({person: householdMember}: {person: any}) =>
                  householdMember.dependentOfTaxFilerId?.value ===
                    jointlyFiler?.id &&
                  householdMember.id?.value !== jointlyFiler?.id &&
                  householdMember.taxFilingStatus?.value !==
                    TAX_FILING_STATUS_FILING_JOINTLY
              )
              .map(({person}: {person: any}) => person),
            ...householdMembers
              .filter(
                ({person: householdMember}: {person: any}) =>
                  householdMember.dependentOfTaxFilerId?.value ===
                    person.id?.value &&
                  householdMember.id?.value !== person.id?.value &&
                  householdMember.taxFilingStatus?.value !==
                    TAX_FILING_STATUS_FILING_JOINTLY
              )
              .map(({person}: {person: any}) => person),
          ]
        : []

      return {
        nameOfSpouseFilingJointly: jointlyFiler?.name,
        dependents: dependentOfJointlyFiler.length
          ? dependentOfJointlyFiler
          : householdMembers
              .filter(
                ({person: householdMember}: {person: any}) =>
                  householdMember.dependentOfTaxFilerId?.value ===
                    person.id?.value &&
                  householdMember.id?.value !== person.id?.value &&
                  householdMember.taxFilingStatus?.value !==
                    TAX_FILING_STATUS_FILING_JOINTLY
              )
              .map(({person}: {person: any}) => person),
      }
    },
    ['person', 'application', 't']
  ),
  addClasses(classes),
  ({
    person: {
      taxFilingStatus,
      incomeSources,
      taxFilerOfDependent,
      relationshipWithTaxFiler,
      whoSupportWithoutIncome,
      selfSupportWithoutIncome,
      othersSupportWithoutIncome,
    },
    person,
    // incomeSources,
    deductions,
    thisMonth,
    lastMonth,
    _taxFilerOfDependent,
    _relationshipWithTaxFiler,
    nameOfSpouseFilingJointly,
    dependents,
    classes,
    t,
  }) => (
    <Section
      label={`Income Information - ${getExtendedName({
        ...{
          firstName: person?.firstName.value,
          lastName: person?.lastName.value,
          preferredName: '',
          middleName: person?.middleName.value,
          suffix: '',
        },
        t,
      })}`}
    >
      <SubsectionHeader>
        Income Details &nbsp;
        {!whoSupportWithoutIncome?.valid ||
        !selfSupportWithoutIncome?.valid ||
        !othersSupportWithoutIncome?.valid ? (
          <span className={classes.error}>
            Supporter detail is insufficient
          </span>
        ) : null}
      </SubsectionHeader>
      {incomeSources && incomeSources.length > 0 ? (
        incomeSources.map((incomeSource: any, index: number) => {
          const {
            id,
            incomeType,
            employerName,
            employerInsurance,
            fullPartTime,
            employerAddressStreet,
            employerAddressCity,
            employerAddressState,
            employerAddressZip,
            employerPhone,
            startMonth,
            startYear,
            endMonth,
            endYear,
            payFrequency,
            amount,
          } = incomeSource
          return (
            <div key={id.value}>
              <Body1 className={classes.subheader}>Income #{index + 1} -</Body1>
              <Item
                label="Employment Type"
                value={incomeType?.value}
                required={incomeType?.required}
              />
              <Item
                label="Work Type"
                value={fullPartTime?.value}
                required={fullPartTime?.required}
              />
              <Item
                label="Employer Name"
                value={employerName?.value}
                required={employerName?.required}
              />
              <Item
                label="Employer provides insurance"
                value={employerInsurance?.value ?? false}
                required={employerInsurance.requierd}
              />
              {employerInsurance?.value ? (
                <>
                  <Item
                    label="Employer Address1"
                    value={employerAddressStreet?.value}
                    required={employerAddressStreet?.required}
                  />
                  <Item
                    label="Employer Address city"
                    value={employerAddressCity?.value}
                    required={employerAddressCity?.required}
                  />
                  <Item
                    label="Employer Address state"
                    value={employerAddressState?.value}
                    required={employerAddressState?.required}
                  />
                  <Item
                    label="Employer Address zip"
                    value={employerAddressZip?.value}
                    required={employerAddressZip?.required}
                  />
                  <Item
                    label="Employer work phone number"
                    value={employerPhone?.value}
                    required={employerPhone?.required}
                  />{' '}
                </>
              ) : null}

              <Item
                label="Job start date (mm/yyyy)"
                value={
                  startMonth?.value &&
                  startYear?.value &&
                  getDateFromMonthAndYear(startMonth?.value, startYear?.value)
                }
                required={startMonth?.required}
                dateFormat="MM/yyyy"
              />
              <Item
                label="Job end date (mm/yyyy)"
                value={
                  endMonth?.value &&
                  endYear?.value &&
                  getDateFromMonthAndYear(endMonth?.value, endYear?.value)
                }
                required={endMonth?.required}
                dateFormat="MM/yyyy"
              />
              <Item
                label="Payment Period"
                value={payFrequency?.value}
                required={payFrequency?.required}
              />
              <Item
                label="Work income (before taxes per pay period)"
                value={amount ? formatCurrency(amount?.value) : ''}
                required={amount?.required}
              />
              <Item
                label="Monthly income"
                value={formatCurrency(
                  getAmountForMonth({
                    month: includesMonth(thisMonth) ? thisMonth : lastMonth,
                    frequency: payFrequency?.value,
                    amount: amount?.value,
                    startMonth: startMonth?.value,
                    startYear: startYear?.value,
                    endMonth: endMonth?.value,
                    endYear: endYear?.value,
                  })
                )}
                required={true}
              />
            </div>
          )
        })
      ) : (
        <ItemValue>No Income</ItemValue>
      )}
      {/* <Item
        label="Has this person had a change in their employment status in the last 6 months?"
        value={changeJobInLast6Mon?.value}
        required={changeJobInLast6Mon?.required}
      /> */}

      <SubsectionHeader>Allowable deductions</SubsectionHeader>
      {deductions && deductions.length > 0 ? (
        deductions.map((deduction: any, index: number) => {
          const {
            id,
            deductionType,
            startMonth,
            startYear,
            endMonth,
            endYear,
            deductionFrequency,
            amount,
          } = deduction
          return (
            <div key={id}>
              <Body1 className={classes.subheader}>
                Deduction #{index + 1} -
              </Body1>
              <Item
                label="Payment Type"
                value={deductionType?.value}
                required={deductionType?.required}
              />
              <Item
                label="Start date (mm/yyyy)"
                value={
                  startMonth &&
                  startYear &&
                  getDateFromMonthAndYear(startMonth?.value, startYear?.value)
                }
                required={startMonth?.required}
                dateFormat="MM/yyyy"
              />
              <Item
                label="End date (mm/yyyy)"
                value={
                  endMonth &&
                  endYear &&
                  getDateFromMonthAndYear(endMonth?.value, endYear?.value)
                }
                required={endMonth?.required}
                dateFormat="MM/yyyy"
              />
              <Item
                label="Payment Period"
                value={deductionFrequency?.value}
                required={deductionFrequency?.required}
              />
              <Item
                label="Payment Amount"
                value={amount ? formatCurrency(amount?.value) : ''}
                required={deductionFrequency?.required}
              />
              <Item
                label="Monthly deduction"
                value={formatCurrency(
                  getAmountForMonth({
                    month: includesMonth(thisMonth)(deduction)
                      ? thisMonth
                      : lastMonth,
                    frequency: deductionFrequency,
                    amount,
                    startMonth,
                    startYear,
                    endMonth,
                    endYear,
                  })
                )}
                required={amount?.required}
              />
            </div>
          )
        })
      ) : (
        <ItemValue>No Deductions</ItemValue>
      )}
      <SubsectionHeader>
        Tax details &nbsp;
        {!taxFilingStatus?.valid && (
          <span className={classes.error}>
            Spouse filing jointly not found.
          </span>
        )}
      </SubsectionHeader>
      <Item
        label="Are you planning to file taxes?"
        value={
          !taxFilingStatus?.value
            ? ''
            : taxFilingStatus?.value !== TAX_FILING_STATUS_NOT_FILING &&
              taxFilingStatus?.value !==
                TAX_FILING_STATUS_DEPENDENT /* &&
              new Date().getFullYear() -
                new Date(person?.dob?.value || '').getFullYear() >=
                18 */
        }
        required={true}
      />
      <Item
        label="Tax Filing Status"
        value={taxFilingStatus?.value}
        required={taxFilingStatus?.required}
      />
      <Item
        label="Will you be claimed as a dependent on someone’s tax return?"
        value={
          !taxFilingStatus?.value
            ? ''
            : taxFilingStatus?.value === TAX_FILING_STATUS_DEPENDENT
        }
        required={false}
      />
      {taxFilingStatus?.value &&
      taxFilingStatus?.value === TAX_FILING_STATUS_DEPENDENT ? (
        <>
          <Item
            label="If Yes, please list the name of the tax filer"
            value={
              _taxFilerOfDependent
                ? getExtendedName({
                    ...{
                      firstName: _taxFilerOfDependent?.firstName.value,
                      lastName: _taxFilerOfDependent?.lastName.value,
                      preferredName: '',
                      middleName: _taxFilerOfDependent?.middleName.value,
                      suffix: '',
                    },
                    t,
                  })
                : ''
            }
            required={taxFilerOfDependent?.required}
          />
          <Item
            label="How are you related to tax filer?"
            value={_relationshipWithTaxFiler}
            required={relationshipWithTaxFiler?.required}
            inValid={!_relationshipWithTaxFiler}
          />
        </>
      ) : null}
      <Item
        label="Will you file jointly with spouse?"
        value={
          !taxFilingStatus?.value
            ? ''
            : taxFilingStatus?.value === TAX_FILING_STATUS_FILING_JOINTLY
        }
        required={false}
      />
      {taxFilingStatus?.value &&
      taxFilingStatus?.value === TAX_FILING_STATUS_FILING_JOINTLY ? (
        <Item
          label="If yes, name of spouse"
          value={nameOfSpouseFilingJointly}
          required={taxFilingStatus?.value === TAX_FILING_STATUS_FILING_JOINTLY}
        />
      ) : null}

      <Item
        label="Will you claim any dependents on your tax return?"
        value={dependents.length > 0}
        required={false}
      />
      {dependents.length > 0 ? (
        <Item label="Dependent Member" required={dependents.length > 0}>
          {dependents.map((dependent: any) => (
            <ItemValue key={dependent.id}>
              {getExtendedName({
                ...{
                  firstName: dependent?.firstName.value,
                  lastName: dependent?.lastName.value,
                  preferredName: '',
                  middleName: dependent?.middleName.value,
                  suffix: '',
                },
                t,
              })}
            </ItemValue>
          ))}
        </Item>
      ) : null}
    </Section>
  )
)

interface MemberInformationSectionProps {
  person: any
}

const MemberInformationSection: FC<MemberInformationSectionProps> = flowMax(
  addDisplayName('MemberInformationSection'),
  addTranslationHelpers,
  ({
    person: {
      wantsPresumptiveEligibility,
      wantsNJFC,
      initialDateOfService,
      isPatient,
      ssn,
      hasInsurance,
      currentInsuranceName,
      currentInsurancePolicyNo,
      alaskanNativeamerican,
      race,
      ciStatus,
      dateOfEntry,
      ciDocumentType,
      uscisNo,
      prCardNo,
      ciDocumentName,
      fosterCareAfter18,
      isStudent,
      insuranceLast3Months,
    },
    person,
    t,
  }) => (
    <Section
      label={`Member Information - ${getExtendedName({
        ...{
          firstName: person?.firstName.value,
          lastName: person?.lastName.value,
          preferredName: '',
          middleName: person?.middleName.value,
          suffix: '',
        },
        t,
      })}`}
    >
      <Item
        label="Does this person want Presumptive Eligibility?"
        value={wantsPresumptiveEligibility?.value}
        required={wantsPresumptiveEligibility?.required}
      />
      <Item
        label="PE Service Start Date"
        value={initialDateOfService?.value}
        required={initialDateOfService?.required}
        inValid={!initialDateOfService?.valid}
      />
      <Item
        label="Does this person want NJ FamilyCare?"
        value={wantsNJFC?.value}
        required={wantsNJFC?.required}
      />
      <Item
        label="Social Security Number"
        value={getFormattedSsn({ssn: ssn?.value, t})}
        required={ssn?.required}
      />
      <Item
        label="US Citizen?"
        value={ciStatus?.value === CI_STATUS_US_CITIZEN ? 'Yes' : 'No'}
        required={ciStatus?.required}
      />
      {ciStatus?.value !== CI_STATUS_US_CITIZEN && (
        <>
          <Item
            label="If you are not a US Citizen, please enter your date of entry"
            value={dateOfEntry?.value}
            required={dateOfEntry?.required}
          />
          <Item
            label="CI Document Type"
            value={ciDocumentType?.value}
            required={ciDocumentType?.required}
          />
          <Item
            label="Name as per CI Document"
            value={ciDocumentName?.value}
            required={ciDocumentName?.required}
          />
          <Item
            label="Do you have eligible immigration status?"
            value={
              ciStatus?.value === CI_STATUS_LEGAL_RESIDENT ||
              ciStatus?.value === CI_STATUS_US_CITIZEN
                ? 'Yes'
                : 'No' // Mother who is Other status and pregnant is also eligible.  We will revisit this condition of eligibility
            }
            required={false}
          />
          <Item
            label="Alien/USCIS Number"
            value={uscisNo?.value}
            required={uscisNo?.required}
            inValid={!uscisNo?.valid}
          />
          <Item
            label="Immigration card #"
            value={prCardNo?.value}
            required={prCardNo?.required}
            inValid={!prCardNo?.valid}
          />
        </>
      )}

      <Item
        label="Were you in foster care at age 18 or older?"
        value={'No'}
        required={fosterCareAfter18?.required}
      />
      <Item
        label="Full-time student?"
        value={isStudent?.value}
        required={isStudent?.required}
      />

      <Item
        label="Does this person have Health Insurance?"
        value={hasInsurance?.value}
        required={hasInsurance?.required}
      />
      {hasInsurance?.value && (
        <>
          <Item
            label="Name of insurance company"
            value={currentInsuranceName?.value}
            required={currentInsuranceName?.required}
          />
          <Item
            label="Insurance policy number"
            value={currentInsurancePolicyNo?.value}
            required={currentInsurancePolicyNo?.required}
          />
        </>
      )}

      <Item
        label="Is this person currently enrolled in Medicaid?"
        value=""
        required={false}
      />
      <Item label="Race" value={race?.value} required={false} />
      <Item
        label="Are you on anyone in your family Native American Indian or Alaska Native?"
        value={alaskanNativeamerican?.value || 'No'}
        required={alaskanNativeamerican?.required}
      />

      <Item
        label="Has this person had other health insurance in the last three months other than Medicaid?"
        value={insuranceLast3Months?.value || 'No'}
        required={insuranceLast3Months?.required}
      />
      <Item
        label="Does the member requesting coverage have medical bills for the last 3 months that have not been paid?"
        value={isPatient?.value}
        required={false}
      />
    </Section>
  )
)

interface HouseholdInformationSectionProps {
  person: any
}

const HouseholdInformationSection: FC<HouseholdInformationSectionProps> = flowMax(
  addDisplayName('HouseholdInformationSection'),
  addTranslationHelpers,
  ({
    person: {
      firstName,
      middleName,
      lastName,
      dob,
      gender,
      pregnant,
      expectedChild,
      dueDate,
      maritalStatus,
    },
    t,
  }) => (
    <Section
      label={`${getExtendedName({
        ...{
          firstName: firstName.value,
          lastName: lastName.value,
          preferredName: '',
          middleName: middleName.value,
          suffix: '',
          t,
        },
        t,
      })}`}
    >
      <Item
        label="FirstName"
        value={firstName?.value}
        required={firstName?.required}
        inValid={!firstName?.valid}
      />
      <Item
        label="MiddleName"
        value={middleName?.value}
        required={false}
        inValid={!middleName?.valid}
      />
      <Item
        label="LastName"
        value={lastName?.value}
        required={lastName?.required}
        inValid={!lastName?.valid}
      />
      <Item
        label="DOB"
        value={dob?.value}
        required={dob?.required}
        dateFormat="MM/dd/yyyy"
        inValid={!dob?.valid}
      />
      <Item label="Sex" value={gender?.value} required={gender?.required} />
      <Item
        label="Is Pregnant"
        value={pregnant?.value}
        required={pregnant?.required}
      />
      <Item
        label="Number of babies expected"
        value={expectedChild?.value}
        required={expectedChild?.required}
      />
      <Item
        label="Pregnant Due Date"
        value={dueDate?.value}
        required={dueDate?.required}
        inValid={!dueDate?.valid}
      />
      <Item
        label="Marital Status"
        value={maritalStatus?.value}
        required={maritalStatus?.required}
      />
    </Section>
  )
)

interface SaveButtonProps extends ComponentProps<typeof Button> {}

const SaveButton: FC<SaveButtonProps> = flowMax(
  addDisplayName('SaveButton'),
  ({disabled, ...props}) => <Button {...props} disabled={disabled} />
)

const MedicaidApplicationSubmission: FC = flowMax(
  addDisplayName('MedicaidApplicationView'),
  addRouteParams<{id: string}>(),
  addApplicationForViewQuery({
    variables: ({id}) => ({id}),
  }),
  addLoadingIndicator({}),
  declarePropTypesNarrowing<{
    application: ApplicationForView_application_MedicaidApplication
  }>(),
  addTranslationHelpers,
  addStateHandlers(
    {
      submitted: false,
    },
    {
      setSubmitted: () => (submitted: boolean) => ({
        submitted,
      }),
    }
  ),
  addProps(({application}) => ({
    applicationId: application.id,
    personId: application.person.id,
  })),
  addNjMedicaidApplicationStatesQuery({
    variables: ({applicationId}) => ({applicationId}),
  }),
  addLoadingIndicator({}),
  addProps(
    ({njMedicaidApplicationStates, t}) => {
      let medicaidApplicationStates: any = null

      if (njMedicaidApplicationStates && njMedicaidApplicationStates.length) {
        const medAppState: NjMedicaidApplicationStates_njMedicaidApplicationStates =
          njMedicaidApplicationStates[0]
        medicaidApplicationStates = {
          medicaidApplicationStatus: t(
            'applicationForm.validationPage.medicaidApplicationApplyPendingStatus'
          ),
          medicaidApplicationErrorMessage: medAppState?.errorMessage,
        }
        switch (medAppState?.processState) {
          case 'apply':
            switch (medAppState?.status) {
              case 'pending':
                medicaidApplicationStates = {
                  ...medicaidApplicationStates,
                  medicaidApplicationErrorMessage: '',
                  medicaidApplicationStatus: t(
                    'applicationForm.validationPage.medicaidApplicationApplyPendingStatus'
                  ),
                }
                break
              case 'success':
                medicaidApplicationStates = {
                  ...medicaidApplicationStates,
                  medicaidApplicationErrorMessage: '',
                  medicaidApplicationStatus: t(
                    'applicationForm.validationPage.medicaidApplicationApplySuccessStatus'
                  ),
                }
                break
              case 'failed':
                medicaidApplicationStates = {
                  ...medicaidApplicationStates,
                  medicaidApplicationStatus: t(
                    'applicationForm.validationPage.medicaidApplicationApplyFailedStatus'
                  ),
                }
                break
              default:
                break
            }
            break
          case 'submit':
            switch (medAppState?.status) {
              case 'pending':
                medicaidApplicationStates = {
                  ...medicaidApplicationStates,
                  medicaidApplicationStatus: t(
                    'applicationForm.validationPage.medicaidApplicationSubmitPending'
                  ),
                }
                break
              case 'success':
                medicaidApplicationStates = {
                  ...medicaidApplicationStates,
                  medicaidApplicationErrorMessage: '',
                  medicaidApplicationStatus: t(
                    'applicationForm.validationPage.medicaidApplicationSubmitSuccessStatus'
                  ),
                }
                break
              case 'failed':
                medicaidApplicationStates = {
                  ...medicaidApplicationStates,
                  medicaidApplicationStatus: t(
                    'applicationForm.validationPage.medicaidApplicationSubmitFailedStatus'
                  ),
                }
                break
              default:
                break
            }
            break
          case 'processed':
            switch (medAppState?.status) {
              case 'approved':
                medicaidApplicationStates = {
                  ...medicaidApplicationStates,
                  medicaidApplicationErrorMessage: '',
                  medicaidApplicationStatus: t(
                    'applicationForm.validationPage.medicaidApplicationProcessedApprovedStatus'
                  ),
                }
                break
              case 'denied':
                medicaidApplicationStates = {
                  ...medicaidApplicationStates,
                  medicaidApplicationStatus: t(
                    'applicationForm.validationPage.medicaidApplicationProcessedDeniedStatus'
                  ),
                }
                break
              default:
                break
            }
            break
          default:
            break
        }
      }

      return {
        medicaidApplicationStatus:
          medicaidApplicationStates?.medicaidApplicationStatus ?? '',
        medicaidApplicationErrorMessage:
          medicaidApplicationStates?.medicaidApplicationErrorMessage,
        medAppStates: njMedicaidApplicationStates?.[0],
      }
    },
    ['njMedicaidApplicationStates', 't']
  ),
  addProps(({application, medAppStates}) => {
    const patient: any = application.householdMembers[0]
    const validationRequired = medAppStates ? false : true
    const _householdMembers: any[] = getHouseholdMembersFromRelationships(
      patient,
      application
    )

    const _allHouseholdMembers: any[] = getAllRelationships(
      patient,
      application
    )
    const _allRelationships: any[] = getAllRelationshipDetails(
      patient,
      application
    )

    const patientAge =
      new Date().getFullYear() -
      new Date(patient?.person?.dob || '').getFullYear()

    const headOfHouseholdParentOrLegalGuardian = getHeadOfHouseholdParentOrLegalGuardian(
      _householdMembers,
      application
    )
    const _headOfHousehold =
      patientAge > 18 && headOfHouseholdParentOrLegalGuardian === null
        ? patient?.person
        : headOfHouseholdParentOrLegalGuardian?.otherPerson

    const _application: any = {
      ...application,
      person: {
        ...application.person,
        additionalComments: null,
        taxFilerOfDependent: null,
        relationshipWithTaxFiler: null,
      },
      allRelationships: _allRelationships,
      householdMembers: _householdMembers,
      allHouseholdMembers: _allHouseholdMembers,
      missingSpouseDetails: null,
      parentCount: null,
      primaryPointOfContact: _headOfHousehold,
    }
    const {modifiedApplication, eligibleForSubmission} = overrideFields(
      _application,
      validationRequired, // validate only if the app is not submitted
      {
        _householdMembers,
        _application,
      }
    )
    return {
      modifiedApplication,
      eligibleForSubmission,
    }
  }),
  addProps(
    ({applicationId}) => ({
      refetchQueries: [
        {
          query: NJ_MEDICAID_STATE_QUERY,
          variables: {applicationId},
        },
      ],
    }),
    ['applicationId']
  ),
  addProps(
    ({modifiedApplication: {householdMembers}}) => ({
      householdMembersWithIncome: householdMembers.filter(
        ({person: householdMember}: {person: any}) =>
          householdMember?.incomeSources?.length > 0
      ),
    }),
    ['modifiedApplication.householdMembers']
  ),
  addProps(
    ({
      modifiedApplication: {householdMembers},
      householdMembersWithIncome,
    }) => ({
      householdMembersWithSupportDetails:
        householdMembersWithIncome.length === 0
          ? householdMembers
              .filter(
                ({person: householdMember}: {person: any}) =>
                  householdMember?.taxFilingStatus?.value !==
                  TAX_FILING_STATUS_DEPENDENT
              )
              .filter(
                ({person: householdMember}: {person: any}) =>
                  householdMember?.whoSupportWithoutIncome?.value &&
                  ((householdMember?.whoSupportWithoutIncome?.value ===
                    WHO_SUPPORT_WITHOUT_INCOME_SELF &&
                    householdMember?.selfSupportWithoutIncome?.value) ||
                    (householdMember?.whoSupportWithoutIncome?.value ===
                      WHO_SUPPORT_WITHOUT_INCOME_OTHER &&
                      householdMember?.othersSupportWithoutIncome?.value))
              )
          : [],
    }),
    ['modifiedApplication.householdMembers', 'householdMembersWithIncome']
  ),
  addProps(
    ({t, householdMembersWithIncome, householdMembersWithSupportDetails}) => ({
      additionalCommentsValue: getAdditionalComments(
        t,
        householdMembersWithIncome,
        householdMembersWithSupportDetails
      ),
    }),
    ['householdMembersWithIncome', 'householdMembersWithSupportDetails', 't']
  ),
  addApplyStateMedicaidMutation({}),
  addHandlers({
    onSave: ({
      applicationId,
      personId,
      mutateApplyStateMedicaid,
      refetchNjMedicaidApplicationStates,
      setSubmitted,
    }) => () => {
      setSubmitted(true)
      mutateApplyStateMedicaid({
        variables: {
          applicationId,
          personId,
          stateCode: 'NJ',
        },
      }).then(() => {
        refetchNjMedicaidApplicationStates({applicationId})
      })
    },
  }),
  addMedicaidPortal,
  addClasses(classes),
  ({
    medicaidApplicationStatus,
    medicaidApplicationErrorMessage,
    modifiedApplication: {
      id,
      person: {
        homeAddressStreet,
        homeAddressCity,
        homeAddressState,
        homeAddressZip,
        mailingAddressStreet,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZip,
        phoneNumbers,
        email,
        childrenPcp,
        pcp,
        desiredMco,
        preferredLanguage,
        additionalComments,
      },
      person,
      allRelationships,
      allHouseholdMembers,
      householdMembers,
      missingSpouseDetails,
      parentCount,
      primaryPointOfContact,
    },
    additionalCommentsValue,
    modifiedApplication,
    eligibleForSubmission,
    submitted,
    t,
    classes,
    medicaidPortal,
    onSave,
  }) => (
    <article className={classes.container}>
      <Heading className={classes.header}>
        Medicaid application ({id.value}) for{' '}
        {getExtendedName({
          ...{
            firstName: person?.firstName.value,
            lastName: person?.lastName.value,
            preferredName: '',
            middleName: person?.middleName.value,
            suffix: '',
            t,
          },
          t,
        })}{' '}
        {!medicaidApplicationStatus && medicaidPortal ? (
          <SaveButton
            disabled={!eligibleForSubmission || submitted}
            onClick={onSave}
            color="primary"
            variant="contained"
          >
            {t('applicationForm.validationPage.medicaidApplicationApplyLink')}
          </SaveButton>
        ) : null}
        <Body1 className={classes.itemContainer}>
          {'['}
          <Item label="Validation" required={false}>
            <strong
              className={
                eligibleForSubmission ? classes.success : classes.error
              }
            >
              {eligibleForSubmission ? 'Passed' : 'Failed'}
            </strong>
          </Item>
          {medicaidApplicationStatus ? (
            <>
              {' | '}
              <Item
                label="Status"
                value={medicaidApplicationStatus || '-'}
                required={false}
              />
            </>
          ) : null}
          {medicaidApplicationErrorMessage ? (
            <>
              {' | '}
              <Item
                label="Error"
                value={medicaidApplicationErrorMessage || '-'}
                required={false}
              />
            </>
          ) : null}

          {']'}
        </Body1>
      </Heading>
      <Section label="Home Address">
        <Item
          label="Home Addr1/Street"
          value={homeAddressStreet?.value}
          required={homeAddressStreet?.required}
        />
        <Item
          label="Home City"
          value={homeAddressCity?.value}
          required={homeAddressCity?.required}
        />
        <Item label="Home County" value="" required={false} />
        <Item
          label="Home State"
          value={homeAddressState?.value}
          required={homeAddressState?.required}
        />
        <Item
          label="Home Zip"
          value={homeAddressZip?.value}
          required={homeAddressZip?.required}
        />
      </Section>{' '}
      <Section label="Mailing Address">
        {!(
          mailingAddressStreet.value ||
          mailingAddressCity.value ||
          mailingAddressState.value ||
          mailingAddressZip.value
        ) ? (
          <Body1>Same as home address</Body1>
        ) : (
          <>
            <Item
              label="Mail Addr1/Street"
              value={mailingAddressStreet?.value}
              required={mailingAddressStreet?.required}
            />
            <Item
              label="Mail City"
              value={mailingAddressCity?.value}
              required={mailingAddressCity?.required}
            />
            <Item
              label="Mail State"
              value={mailingAddressState?.value}
              required={mailingAddressState?.required}
            />
            <Item
              label="Mail Zip"
              value={mailingAddressZip?.value}
              required={mailingAddressZip?.required}
            />
          </>
        )}
      </Section>
      <Section label="Phone Numbers and Email">
        <Item label="Phone No" required={false}>
          {phoneNumbers
            .filter(
              ({usedInApp}: {usedInApp: {value: any; required: boolean}}) =>
                usedInApp.value
            )
            .map((phoneNumber: {id: any; number: any}) => (
              <ItemValue key={phoneNumber.id?.value}>
                {getFormattedPhoneNumber(phoneNumber.number?.value)}
              </ItemValue>
            ))}
        </Item>
        <Item label="Email" value={email.value} required={false} />
      </Section>
      <Section
        label="Household Members"
        warningLabel={
          !missingSpouseDetails?.valid
            ? 'Spouse detail is missing'
            : !parentCount?.valid
            ? 'More than 2 Parent/Legal Guardian found'
            : ''
        }
      >
        {allHouseholdMembers.map((householdMember: any) => (
          <HouseholdInformationSection
            person={householdMember.person}
            key={householdMember.id}
          />
        ))}
      </Section>
      <Section label="Relationship information">
        {allRelationships.map((relation: any, index: number) => (
          <ItemValue key={index}>
            {relation.from?.value} is a{' '}
            <span className={relation.relationship?.value || classes.error}>
              {relation?.relationship?.value || t('general.unknown')}{' '}
            </span>
            of {relation.to?.value}
          </ItemValue>
        ))}
      </Section>
      {householdMembers.map((householdMember: any) => (
        <MemberInformationSection
          person={householdMember.person}
          key={householdMember.id.value}
        />
      ))}
      {householdMembers.map((householdMember: any) => (
        <IncomeInformationSection
          person={householdMember.person}
          application={modifiedApplication}
          key={householdMember.id.value}
        />
      ))}
      <Section label="Health Plan">
        <SubsectionHeader>Doctor Information</SubsectionHeader>
        <Item
          label="Who is your child’s doctor?"
          value={childrenPcp?.value}
          required={childrenPcp?.required}
        />
        <Item label="Address" value="" required={false} />
        <Item
          label="Who is your doctor?"
          value={pcp?.value}
          required={pcp?.required}
        />
        <Item label="Address" value="" required={false} />
        <Item
          label="Choose Health Plan"
          value={desiredMco?.value ?? 'Horizon NJ Health'}
          required={desiredMco?.required}
        />
        <SubsectionHeader>Other Information</SubsectionHeader>
        <Item
          label="Head of the household"
          value={
            primaryPointOfContact?.firstName && primaryPointOfContact?.lastName
              ? getExtendedName({
                  ...{
                    firstName: primaryPointOfContact?.firstName?.value,
                    lastName: primaryPointOfContact?.lastName?.value,
                    preferredName: '',
                    middleName: primaryPointOfContact?.middleName?.value,
                    suffix: '',
                  },
                  t,
                })
              : ''
          }
          required={true}
        />
        <Item
          label="What language you speak at home"
          value={preferredLanguage.value}
          required={preferredLanguage.required}
        />
      </Section>
      <Section label="Additional Comments">
        <Item
          label="Income/Additional Comments"
          required={additionalComments?.required}
          inValid={!additionalComments?.valid}
        >
          <ItemValue>{additionalCommentsValue}</ItemValue>
        </Item>
      </Section>
    </article>
  )
)

export default MedicaidApplicationSubmission
